// Globally available helpers

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden !important;
}

.display-none {
  display: none !important;
}
