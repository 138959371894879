/*
 *
 *   EGRET - Angular Material Design Admin Template
 *   version 1.0.0
 *   Supported Angular material 5 - Updated by Lola
 *
*/

/*
# Table of contents
## Globals
## Common
## Loader
## Third pirty adjust
    Scroll Bar
    Data table
## Top Bar
## Main Sidenav
## Collapsed Sidenav
## Pages
    Sign in
    sign up
    forgot
    lockscreen
## Forms
## Inbox
## Calendar
## Chats
## Gallery
## Plans & Pricings
## Users
## Media queries
*/


/*---- Globals -----*/
html {
  font-size: 87.5% /* 14px */;
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  min-height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
  width: 100%;
}

body.safari-platform {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

body {
  background: var(--bg1);
  color: rgba(0, 0, 0, 0.87);
  font-family: 'IBM Plex Sans', 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.65;
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

.mat-card {
  font-size: 0.875rem;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a:focus-visible,
button.mat-button.mat-accent:focus-visible,
.ix-dark button:focus-visible,
button:focus-visible {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px var(--focus-brd) inset !important;
  outline-width: 0;
}

a:focus-visible {
  box-shadow: 0 0 0 1px var(--alt-bg2) inset !important;
}

a {
  color: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 0.5rem;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

code {
  background: rgba(0, 0, 0, 0.08);
  font-family: 'Droid Sans Mono', 'Courier New', Courier, monospace !important;
  padding: 8px;
}

samp {
  background: rgba(180, 180, 180, 0.5);
  font-family: 'Droid Sans Mono', 'Courier New', Courier, monospace !important;
  padding: 2px;
}
/*---- Common -----*/
.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.height-100 {
  min-height: 100vh;
}

.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-blue {
  background: #247ba0;
  color: #fefefe;
}

.mat-indigo {
  background-color: #3f51b5 !important;
}

.mat-brown {
  background-color: #785548 !important;
  color: #fefefe;
}

.text-center {
  text-align: center;
}

.font-normal {
  font-weight: normal !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-sm {
  font-size: 0.813rem;
}

.material-icons.icon-sm {
  font-size: 18px !important;
  height: 18px;
  line-height: 18px !important;
  width: 18px;
}

.material-icons.icon-xs {
  font-size: 13px !important;
  height: 13px;
  line-height: 13px;
  width: 13px;
}

.mat-card.default {
  padding: 0;
}

.mat-card.default .mat-card-title {
  font-weight: 600;
  line-height: 1;
  padding: 1rem;
}

.mat-card.default>:first-child {
  border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
  padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
  line-height: 1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.mat-card.default .mat-card-content {
  margin-bottom: 0;
  padding: 1rem;
  position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
  margin: 0;
  padding: 0.5rem;
}

.mat-card.default>:last-child {
  border-radius: 0 0 2px 2px;
}

.mat-card {
  margin: 0.333333rem;
}

.mat-card .mat-card-title .mat-divider,
.mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
  padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
  height: 24px;
  line-height: 24px;
  width: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
  margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.04);
  margin-left: 0;
  margin-right: 0;
}

.mat-card.p-0 .mat-card-image {
  margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
  padding: 0 1.5rem 1.5rem;
}

.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
}

.full-width {
  width: 100%;
}

.mat-card-header .mat-card-title {
  font-size: 1rem;
}

.mat-chip:not(.mat-basic-chip) {
  border-radius: 24px;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 12px;
}

.ix-form-container .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 1;
}

.chart {
  display: block;
  width: 100%;
}

.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}

.ct-legend {
  list-style-type: none;
  position: relative;
  top: -2.3em !important;
  z-index: 10;
}

.ct-legend li {
  margin-bottom: 4px !important;
  margin-left: 5px !important;
  padding-left: 22px !important;
}
/*---- Third party adjust -----*/
/*--------- Material ---------*/
mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.875rem !important;
}

.datatable-header-cell-wrapper {
  padding: 0.5rem;
}

.datatable-body-cell-label {
  overflow: hidden;
  padding: 0.5rem;
}

.empty-row {
  padding: 1rem;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  font-weight: 330;
  padding: 0.5rem;
}

.ngx-datatable.fullscreen {
  height: calc(100vh - 90px);
}

.ngx-datatable.fullscreen .datatable-header {
  background: blue;
  overflow: hidden;
  position: fixed;
  z-index: 999;
}

.ngx-datatable.material {
  background: rgba(255, 255, 255, 0.42);
}

.ngx-datatable.material .datatable-header {
  background: rgba(255, 255, 255, 0.66);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  font-weight: 500;
  padding: 0.5rem;
}

.ngx-datatable.material .datatable-header .datatable-header-cell:hover {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: rgba(0, 0, 0, 0.04);
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  transition-duration: 0.3s;
  transition-property: background;
  transition-timing-function: linear;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 2.2rem;
  overflow: hidden;
  /*padding: .9rem .6rem;
    line-height: 0rem;*/
  padding: 0 16px;
}

.ngx-datatable.material .datatable-body .datatable-body-row:last-child {
  border-bottom: 0 solid black;
}

.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(255, 255, 255, 0);
}

.ngx-datatable.material .datatable-footer .page-count {
  height: 50px;
  line-height: 50px;
  padding: 0 1.2rem;
}

.ngx-datatable .datatable-footer .datatable-pager .pager {
  padding: 0 1.2rem !important;
}

.ngx-datatable .datatable-footer .datatable-pager .pager .pages {
  margin: 0 0.5rem;
}

.ngx-datatable .datatable-footer .datatable-pager .pager .pages a {
  border-radius: 3px;
  height: 22px;
  line-height: 22px;
  min-width: 24px;
  padding: 0 6px;
  text-align: center;
  text-decoration: none;
}

.ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*-------- Chart js ---------*/
.chart {
  margin-left: -10px;
}

/*---- Top Bar -----*/
.topbar.mat-toolbar {
  min-height: 48px;
}

.topbar .mat-select-value {
  color: #fff;
  font-weight: 400;
}

.topbar-button-right {
  margin-left: 0.5rem !important;
}

/*---- Main Sidenav -----*/
.icon-menu {
  padding: 0 24px;
}

.icon-menu .icon-menu-item {
  display: inline-block;
}

.icon-menu .icon-menu-item button {
  margin: 4px;
  min-width: auto;
}

.icon-menu > .mat-divider {
  margin: 1rem 0;
}

.sidebar-panel.mat-sidenav {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: var(--sidenav-width); /*15rem;*/
  width: var(--sidenav-width); /*15rem;*/
}

.sidebar-panel.mat-sidenav .navigation-hold {
  background: rgba(255, 255, 255, 0.95);
  height: 100%;
  padding-top: 40px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.nav-section-title {
  display: block;
  font-size: 12px;
  padding: 16px 0 0 16px;
}

.sidebar-panel.mat-sidenav .mat-nav-list .sidebar-list-item .mat-list-item-content {
  display: block;
  font-size: 0.875rem !important;
  height: auto;
  overflow: hidden;
  padding: 0;
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  width: 100%;
}

.mat-nav-list .mat-list-item-content.mat-list-item-focus,
.mat-nav-list .mat-list-item-content:hover {
  background: transparent;
}

.sidebar-list-item.open > .mat-list-item-content {
  background: rgba(0, 0, 0, 0.03);
}

.sidebar-panel .mat-nav-list a,
.slide-in-nav-item a {
  align-items: center;
  box-align: center;
  box-direction: normal;
  box-orient: horizontal;
  display: flex;
  flex-align: center;
  flex-direction: row;
  height: 48px;
  padding: 0 16px 0 0;
  position: relative;
  z-index: 99;
}

.sidebar-panel.mat-sidenav .sidebar-list-item ix-icon:not(.menu-caret) {
  height: 48px;
  line-height: 48px;
  margin-right: 8px;
  text-align: center;
  width: 48px;
}

.sidebar-panel.mat-sidenav .mat-list-item-content > a,
.slide-in-nav-item a {
  box-direction: normal;
  box-orient: horizontal;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.slide-in-nav-item a:focus-visible {
  box-shadow: none !important;
}

.sidebar-panel.mat-sidenav .sidebar-list-item.open > .mat-list-item-content > .sub-menu {
  max-height: 1000px;
}

.sidebar-panel.mat-sidenav .sidebar-list-item .sub-menu {
  padding: 0;
  transition: max-height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-panel.mat-sidenav .sidebar-list-item .sub-menu .mat-list-item {
  transition: height 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-panel.mat-sidenav .sidebar-list-item .sub-menu .mat-list-item.selected {
  background: rgba(0, 0, 0, 0.05);
}

.sidebar-panel.mat-sidenav .sub-menu a {
  padding-left: 54px;
}

.app-side-nav-container .mat-sidenav-content {
  padding: 0;
}

/*------- Collapsed Sidenav -------*/
.collapsed-menu .sidebar-panel {
  min-width: 48px;
  overflow: visible !important;
  width: 48px;
}

.collapsed-menu .sidebar-panel .sidebar-list-item {
  border-left: 0 solid rgba(0, 0, 0, 0) !important;
  width: 100% !important;
}

.collapsed-menu .sidebar-panel .navigation-hold,
.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content {
  overflow: visible !important;
}

.collapsed-menu .sidebar-panel .icon-menu {
  padding: 0;
}

.collapsed-menu .icon-menu .icon-menu-item button {
  margin: 0 0 4px;
  padding: 0 12px;
}

.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content > a {
  padding: 0;
  position: relative;
}

.collapsed-menu .sidebar-panel .sidebar-list-item > .mat-list-item-content > a .menu-item-tooltip {
  background: transparent;
  display: block;
  height: 48px;
  left: 0;
  position: absolute;
  top: 0;
  width: 48px;
}

.collapsed-menu .sidebar-panel .branding {
  max-width: 48px;
}

.collapsed-menu .sidebar-panel .branding .app-logo-text {
  display: none;
}

.collapsed-menu .sidebar-panel .branding .app-logo {
  margin: 0;
}

.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu {
  background: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  display: block;
  left: 47px;
  max-height: 600px !important;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 200px;
  z-index: 9999;
}

.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu .mat-list-item a {
  padding-left: 1rem;
}

.collapsed-menu .sidebar-panel .mat-nav-list > div > .sidebar-list-item a .mat-icon {
  margin: 0;
}

.collapsed-menu .sidebar-panel .mat-nav-list > div > .sidebar-list-item a span:not(.menu-item-tooltip),
.collapsed-menu .sidebar-panel .nav-section-title,
.collapsed-menu .sidebar-panel .icon-menu-separator,
.collapsed-menu .sidebar-panel .app-user,
.collapsed-menu .sidebar-panel .sub-menu {
  display: none;
}

.collapsed-menu .sidebar-panel .mat-nav-list > div >  .sidebar-list-item a .menu-caret {
  font-size: medium;
  left: 33px;
  position: absolute;
  top: 16px;
}

/*---- Pages (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 40px 1rem;
}

.session-progress {
  position: absolute !important;
  top: 0;
  z-index: 9999;
}

.session-form-hold {
  margin: 0 auto;
  max-width: 400px;
  position: relative;
  width: 100%;
}

.session-form-hold > .mat-card {
  border: 0;
  margin: 0;
}

/*---- Forms -----*/
.fileupload-drop-zone {
  border: 1px dashed rgba(0, 0, 0, 0.15);
  height: 120px;
  padding: 48px;
  text-align: center;
}

.default-table {
  text-align: left;
}

.default-table > thead  tr th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 400;
  padding: 0.9rem 1.2rem;
}

.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}

/*---- Chrome autocomplete Forms -----*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-visible,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bg1) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--fg1);
}

/*---- Inbox -----*/
.messages.mat-list,
.messages .mat-list-item-content {
  font-size: 0.875rem !important;
  padding: 0 !important;
}

/*---- Calendar -----*/
.card-title-text.calendar-title {
  padding: 0.5rem 1.5rem !important;
}

.cal-event-action .material-icons {
  margin: 6px;
  vertical-align: sub;
}

.cal-open-day-events {
  box-shadow: none !important;
}

/*---- Chats -----*/
.mat-grid-tile .mat-figure {
  align-items: left !important;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: left !important;
  left: 0;
  line-height: 25px !important;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mat-menu-panel.mat-menu-after.mat-menu-below {
  min-width: 140px !important;
}

/*---- Media queries -----*/
@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
}

/*---- Modal Dialog ----*/
.overflow-hide {
  overflow: hidden !important;
}

.system-info .mat-list .mat-list-item-content {
  height: 40px !important;
}

@media (max-width: 415px) {
  .system-info .mat-list .mat-list-item-content {
    height: 50px !important;
  }
}

.system-info .mat-list .mat-list-item-content label,
.system-info .mat-list .mat-list-item-content p {
  overflow: visible;
  white-space: normal;
  word-break: keep-all;
}

/*---- tooltip ----*/
.tooltip .tooltiptext a {
  color: #bbe2f9;
  text-decoration: underline;
}

.tooltip .tooltiptext a:hover {
  text-decoration: none;
}

.has-tooltip mat-form-field.full-width,
.has-tooltip mat-select.full-width {
  width: 100%;
}

.has-tooltip .tooltip .material-icons {
  vertical-align: middle;
}

/*---- Safari compatibility - to overwrite the current css style ----*/
body.safari-platform {
  .mat-fab,
  .mat-mini-fab,
  .mat-raised-button {
    transform: none;
  }
}

/*---- Ascii art font ----*/
.ascii-art-font {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace !important;
  line-height: 1 !important;
}

/*---- widgets buttons alignment ----*/
.widget .primary-nav .back-arrow {
  line-height: 20px;
  vertical-align: middle;
}

.mat-icon.mat-warn {
  color: var(--orange) !important;
}

.mat-tooltip {
  word-break: break-word;
}
