.widget {
  margin-bottom: 32px;
}

.widget .viewchart-wrapper {
  width: 100%;
}

.widget.line .viewchart-wrapper,
.widget.spline .viewchart-wrapper,
.widget.area .viewchart-wrapper,
.widget.area-spline .viewchart-wrapper {
  bottom: 0;
  position: absolute !important;
}

.widget .chart-wrapper {
  border-top: solid 1px var(--bg1);
}

/* Legends */

.legend-wrapper {
  min-height: 32px;
  padding: 0;
}

.legend-item {
  cursor: pointer;
  padding: 8px 0;
}

.legend-item-disabled {
  opacity: 0.15;
}

.legend-x.legend-item {
  font-size: larger;
  font-weight: bold;
  text-align: left;
}

.legend-item .legend-name {
  text-transform: capitalize;
}

.legend-item .legend-value {
  display: inline-block;
  min-width: 60px;
}

.legend-item .legend-swatch {
  border: solid #000 0;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin: 12px 2px 0;
  width: 12px;

  &.primary {
    background-color: var(--primary);
  }

  &.defaultTheme {
    background-color: var(--orange);
  }

  &.nonDefaultTheme {
    background-color: var(--accent);
  }
}

/* Charts */
.c3 text,
.c3-tooltip-container,
.c3-chart-arcs-title,
.c3-gauge-value {
  color: rgba(0, 0, 0, 0.55);
  font-family: 'Droid Sans Mono';
}

.c3-axis-y-label {
  font-size: 16px;
}

.c3-legend-item text {
  font-family: Roboto;
}

/* Chart Tooltips */
.c3-tooltip-container {
  display: none;
}

#dashboardcontainerdiv .c3-tooltip-container {
  display: none;
}

.c3-tooltip,
.c3-tooltip tbody,
.c3-tooltip th,
.c3-tooltip td {
  background: none;
  border: none;
  box-shadow: none;
}

.c3-tooltip tr {
  border: none;
}

.c3-tooltip tr:first-child {
  background-color: none;
}

.c3-tooltip td>span {
  border-radius: 8px;
}

/* GENERAL CHART STYLES */
.c3 .tick text {
  fill: #bbb;
  font-weight: 900;
}

.c3 path.domain,
.c3 .tick line {
  stroke: #ddd !important;
  stroke-opacity: 0.15 !important;
}

.c3-ygrid,
.c3-xgrid {
  stroke: #999 !important;
  stroke-dasharray: none !important;
  stroke-opacity: 0.15;
}

.c3-area {
  opacity: 0.65 !important;
}

.c3-chart-arc path {
  stroke: none;
}

g.c3-chart:nth-child(1) {opacity: 0.55;}
.c3-brush .extent {fill-opacity: 0.3;}

/* Line/Spline charts */
.c3-chart-line .c3-circles circle {
  opacity: 0 !important;
}

.c3-chart-line .c3-circles circle._expanded_ {
  fill-opacity: 1;
  opacity: 1 !important;
  r: 4;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 2px;
}

.c3-chart-line .c3-lines path.c3-line {
  stroke-width: 1px;
}

div.c3.area .c3-chart-line .c3-lines path.c3-line,
div.c3.area-spline .c3-chart-line .c3-lines path.c3-line {
  stroke-width: 0;
}

/* Y axis & ticks  */
.c3-axis-y path.domain {
  stroke-width: 0;
}

.c3-axis-y g.tick line {
  stroke-width: 0;
}

.line .c3-event-rects,
.spline .c3-event-rects,
.area .c3-event-rects,
.area-spline .c3-event-rects {
  cursor: crosshair !important;
}

/* SUBCHARTS */
g.c3-brush + g {
  opacity: 0 !important;
}

/* Titles and text */
.c3-chart-arcs-title,
.gauge .c3-chart-arc text.c3-gauge-value {
  fill: #999;
}

.donut text.c3-chart-arcs-title {
  font-size: 24px;
}

.widget .c3 .c3-axis g.tick tspan {
  fill-opacity: 0.5;
}

.widget .mat-progress-bar-buffer {
  background-color: var(--bg1);
}

.widget .bar-chart .c3-xgrid-focus {
  opacity: 0;
}

/* DyGraph */
.dygraph-axis-label-y,
.dygraph-axis-label-x {
  opacity: 0.5;
}

.dygraph-label {
  opacity: 0.75;
}

.dygraph-ylabel {
  text-align: center;
  transform: rotate(-90deg);
}

